import { axiosInterceptor } from './axiosInterceptor'

export default (payload, token, shop, host) => {
  const axiosWithToken = axiosInterceptor(shop, host)
  return axiosWithToken.request({
    url: `/sendFeedbackMessage`,
    method: 'post',
    data: payload,      
    headers: {
        'X-Shopify-Access-Token': token,
        'X-Shopify-Shop-Domain': shop,
    },
  })
}