import React from "react"
import { Modal, FormLayout, TextField } from "@shopify/polaris"
const InfoModal = (props) => {
  const {
    activator,
    requestModalActive,
    handleRequestModalChange,
    title,
    infoText,
    emailText,
    handleFeedbackChange,
    handleEmailChange,
    sendRequest,
    loading,
    feedback,
    email,
    type,
  } = props
  const downloadExample = () => {
    const pdfUrl = "https://app.upsellplus.com/assets/EXAMPLE_Upsell_proposal.pdf"
    const link = document.createElement("a")
    link.href = pdfUrl
    link.download = "EXAMPLE_Upsell_proposal.pdf"
    document.body.appendChild(link)
    window.open(
        link,
        "_blank"
      )
    document.body.removeChild(link)
  }
  return (
    <Modal
      activator={activator}
      open={requestModalActive}
      onClose={handleRequestModalChange}
      title={title}
      primaryAction={{
        content: "Submit",
        loading: loading,
        onAction: sendRequest,
      }}
      secondaryActions={
        "proposal" === type
          ? [
              {
                content: "See an example",
                onAction: downloadExample,
              },
            ]
          : []
      }
    >
      <Modal.Section>
        <FormLayout>
          <TextField
            label={infoText}
            type="text"
            multiline={6}
            value={feedback}
            onChange={handleFeedbackChange}
          />
          <TextField
            label={emailText}
            type="email"
            value={email}
            onChange={handleEmailChange}
            autoComplete="email"
          />
        </FormLayout>
      </Modal.Section>
    </Modal>
  )
}
export default InfoModal
