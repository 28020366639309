import React, {
    useState,
    useCallback,
  } from 'react'
  import { 
    Banner,
    Modal,
    TextField,
    Button,
    Toast,
    FormLayout,
    Frame,
  } from "@shopify/polaris"
  import styled from 'styled-components'
  import sendFeedbackMessage from '../../helpers/sendFeedbackMessage'
  import InfoModal from '../../components/infoModal'
const FrameWrapper = styled.div`
.Polaris-Frame {
  max-height: 0;
  min-height: 0;
}
`
  const FeedbackBanner = (props) => {
    const [bannerActive, setBannerActive] = useState(true);
    const [email, setEmail] = useState(null);
    const [feedback, setFeedback] = useState(null);
    const [requestSent, setRequestSent] = useState(false);
    const [toastActive, setToastActive] = useState(null);
    const toggleToastActive = () => setToastActive(null)
    const [loading, setLoading] = useState(false);
    const [requestModalActive, setRequestModalActive] = useState(false);
    const handleRequestModalChange = useCallback(() => setRequestModalActive(!requestModalActive), [requestModalActive]);
    const handleEmailChange = useCallback((newValue) => setEmail(newValue), []);
    const handleFeedbackChange = useCallback((newValue) => setFeedback(newValue), []);
    const sendRequest = async () => {
      if(!validateEmail(email)){
          setToastActive("WrongEmail")
      }else{
          setLoading(true)
          const result = await sendFeedbackMessage({
              feedback: feedback,
              email: email
          }, props.token, props.shop, props.host);
          setLoading(false)
          if(200 === result.status){
              setRequestModalActive(false)
              setToastActive("Success")
              setRequestSent(true)
          }else{
              setToastActive("Error")
          }
      
      }
  }
  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }
  const toastMarkup = toastActive && "Success" === toastActive ? (
    <Toast content="We have received your feedback, thanks!" onDismiss={toggleToastActive} />
  ) : (toastActive && "WrongEmail" === toastActive ? (
    <Toast 
    content="Please enter a valid email address." 
    onDismiss={toggleToastActive} error/>
  ) : (toastActive && "Error" === toastActive ? <Toast 
  content="Something is wrong. Please contact us in the chat on the bottom right." 
  onDismiss={toggleToastActive} error/>:null));


    return (
      <div>
        {bannerActive &&
        <Banner
              title="What more can we do for you?"
              status="info"
              onDismiss={() => {setBannerActive(false)}}
                >
               <p className="feedback-banner-text">We release new updates every week and would love to help you achieve your goals better. What can we improve?
                 {
                 requestSent ? <div>
                  <Button disabled size="slim">Feedback submitted!</Button>
                 </div>
                    :
                    <div>
                      <InfoModal 
                        activator={(<Button onClick={handleRequestModalChange} size="slim">Share with the team</Button>)}
                        requestModalActive={requestModalActive} 
                        handleRequestModalChange={handleRequestModalChange}
                        title="What more can we do for you?"
                        infoText="Share your feedback, ideas or requests" 
                        emailText="Where can we reach you?" 
                        handleFeedbackChange={handleFeedbackChange}
                        handleEmailChange={handleEmailChange}
                        sendRequest={sendRequest}
                        loading={loading}
                        feedback={feedback}
                        email={email}
                      />
                    </div>
                  }
               </p>
             </Banner>
          }
          <FrameWrapper>
          <Frame>{toastMarkup}</Frame>
          </FrameWrapper>
      </div>
    );
  }
  
  export default FeedbackBanner;